<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon
          large
        >
          mdi-router-wireless
        </v-icon>
        ONU
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="onus"
        :search.sync="search"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions : [10,50,100]}"
        :loading="loading"
        loading-text="Loading..."
        :server-items-length="totalOnus"
        class="elevation-1"
      >
        <template v-slot:item.updatedAt="{ item }">
          <span>
            {{ formatDate(item.updatedAt) }}
          </span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            small
            color="primary"
            class="ma-2"
            outlined
            :to="'/onu/' + item._id"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
            Detail
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

moment.locale('th')

export default {
  name: 'OnuList',
  props: {

  },
  data() {
    return {
      search: '',
      totalOnus: 0,
      options: {},
      onus: [],
      loading: false,
      headers: [
        {
          text: 'Name',
          value: 'name.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'SN',
          value: 'SN.SN',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Model',
          value: 'model.model',
          sortable: false,
          align: 'center',
        },
        {
          text: 'OLT',
          value: 'olt.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'OLT IP Address',
          value: 'olt.ip',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Rack',
          value: 'rack',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Shelf',
          value: 'shelf',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Slot',
          value: 'slot',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Port',
          value: 'port',
          sortable: false,
          align: 'center',
        },
        {
          text: 'ONU',
          value: 'onu',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Sector',
          value: 'olt.officeSector.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Updated at',
          value: 'updatedAt',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI()
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    setStatusColor(enable, uptime) {
      if ((enable) && (uptime !== null)) return 'success'
      if ((enable) && (uptime === null)) return 'error'
      return ''
    },
    calSysUpTime(upTime) {
      const uptimeSecond = upTime / 100
      const day = Math.floor(uptimeSecond / (86400))
      const hour = Math.floor((uptimeSecond % 86400) / 3600)
      const minute = Math.floor((uptimeSecond % 3600) / 60)
      const upTimeText = ` ${day} วัน ${hour} ชั่วโมง ${minute} นาที `
      return upTimeText
    },
    formatDate(date) {
      return moment(date).format('LLL')
    },
    getDataFromAPI() {
      this.loading = true
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
      }
      axios.get('/onu', queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.onus = response.data.docs
          this.totalOnus = response.data.totalDocs
          this.loading = false
        })
        .catch((e) => {
          this.errors.push(e)
          this.loading = false
        })
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
